import { useState, useEffect} from 'react';
import { Link, useSearchParams} from 'react-router-dom';
import { payKey } from './store';
import { mesKey } from './storeMes'
import './card.css'





function Card() {
const [searchParams, setSearchParams] = useSearchParams();
const [mes, setMes] = useSearchParams();

  const code =  searchParams.get("id")
  const message = mes.get("mes_id")
  
  useEffect(()=>{
    mesKey.setData(message);
  })

   useEffect(()=>{
    payKey.setData(code);
   })

   
    const [info, setInfo] = useState([])
    useEffect(()=>{
      fetch(`https://murtaza-bot.ru/pay/type/`)
      .then(response => response.json())
      .then(result => setInfo(result))
      },[])


    return (
     <>
     <div className='container1'>
      <div>
      </div>
         {   info.map((value)=>{
          return(
          
            <Link to='payment' key={value.name}>
            
            <div  className='paytype'>
                <div className='photo'>
                  <img src={value.image} alt="icon"/>
                  <span>{value.name}</span>
                </div>
                <div className='text'><p>{value.text}</p></div>
              </div>       
            </Link>
             )
            })
         }
           </div>

       
     
     </>
    ); }
  
  export default Card