class Store {
    #data = "";
    constructor() {
      this.#data = localStorage.getItem("mes") || "";
    }
    /** @param {string} data */
    setData(data) {
      this.#data = data;
      localStorage.setItem("mes", data);
    }
  
    get data() {
      return this.#data;
    }
  }
  
  const mesKey = new Store();
  
  export { mesKey };
  