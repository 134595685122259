
import Payment from "../Payment/payment"
import App from "../../App";
import Error from "../Error/error";
import { Route, Routes } from "react-router-dom";
import { LinkedInCallback } from 'react-linkedin-login-oauth2';





const Root = () =>{
    return(
        <>
           <Routes>
                   <Route path="/" element={<App/>}/>
                   <Route path="/payment" element={<Payment />} />
                   <Route path="/error" element={<Error/>}/> 
                   <Route path="/linkedin" element={<LinkedInCallback/>}/>    
           </Routes>
        </>
    )
}

export default Root