import Card from './components/Card/card.jsx'
import './App.css';
import { useState, useEffect} from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import LinkedInPage from './components/Linkend/linked.jsx';



function App() {
  
  const [data, setData] = useState([])

  useEffect(()=>{
    fetch(`https://murtaza-bot.ru/bot/`)
    .then(response => response.json())
    .then(result => setData(result))
    },[])
        
  return (
    
   <>
      
   <Fade>
    <div className='text'>

    {
      data.map((value)=>{
        return(
          <div key={value}>
          <Slide cascade >
          <a  href={value.bot_url}> {value.bot_name}</a>
          </Slide>
          </div>
        )
      })
    }

    </div>
     <div className='osnova'> 
     <Card/>
     </div>
     </Fade>
   </>
  );
}

export default App;
