import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './components/Root/root'
import 'i18next'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
         <Suspense fallback={<div>Loading...</div>}>
           <Root />
         </Suspense>
    </Router>
 
);


