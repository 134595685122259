import { useNavigate } from 'react-router-dom';
import './error.css'
import { Fade, Flip, Hinge, Slide } from 'react-awesome-reveal';
import PC from './computer.webp'

const Error = () =>{


    const navigate = useNavigate()

    return(
        <>
           <div className='wrapper5'>
             <div className='container5'>
             <h1><Fade direction='down'><span style={{  fontFamily: 'Rampart One'}}>404</span></Fade></h1>
           <Fade>  <div className='forimg'><img src={PC} alt="404" /></div></Fade>
             <button onClick={() => navigate('/payment')}>Back to payment</button>
             <h5>Please complete all fields correctly</h5>
             </div>
           </div>
        </> 
    )
}

export default Error