
const Data = [
    {id:1,img:'https://app.0xprocessing.com/img/btc.svg', name:'BTC',},
    {id:2,img:'https://app.0xprocessing.com/img/busd.svg', name:'BUSD',},
    {id:3,img:'https://app.0xprocessing.com/img/dai.svg',name:'DAI',},
    {id:4,img:'https://app.0xprocessing.com/img/axs.svg',name:'AXS',},
    {id:5,img:'https://app.0xprocessing.com/img/dash.svg', name:'DASH',},
    {id:6,img:'https://app.0xprocessing.com/img/doge.svg', name:'DOGE',},
    {id:7,img:'https://app.0xprocessing.com/img/eth.svg', name:'ETH',},
    {id:8,img:'https://app.0xprocessing.com/img/ltc.svg', name:'LTC',},
    {id:9,img:'https://app.0xprocessing.com/img/tusd.svg', name:'TUSD',},
    {id:10,img:'https://app.0xprocessing.com/img/usdc.svg', name:'USDC',},
    {id:11,img:'https://app.0xprocessing.com/img/usdt.svg', name:'USDT',},
    {id:12,img:'https://app.0xprocessing.com/img/usdt.svg', name:'USDT (OMNI)',},
    {id:13,img:'https://app.0xprocessing.com/img/xrp.svg', name:'XRP',},
    {id:14,img:'https://app.0xprocessing.com/img/trx.svg', name:'TRX',},
    {id:15,img:'https://app.0xprocessing.com/img/usdt.svg', name:'USDT (TRC20)',},
    {id:16,img:'https://app.0xprocessing.com/img/axs.svg', name:'AXS (TRC20)',},
    {id:17,img:'https://app.0xprocessing.com/img/ht.svg', name:'HT (TRC20)',},          
    {id:18,img:'https://app.0xprocessing.com/img/husd.svg', name:'HUSD (TRC20)',},
    {id:19,img:'https://app.0xprocessing.com/img/eth.svg',name:'ETH (TRC20)'},
    {id:20,img:'https://app.0xprocessing.com/img/bnb.svg',name:'BNB',},
    {id:21,img:'https://app.0xprocessing.com/img/busd.svg', name:'BUSD (BEP20)',},
    {id:22,img:'https://app.0xprocessing.com/img/eth.svg', name:'ETH (BEP20)',},          
    {id:23,img:'https://app.0xprocessing.com/img/dai.svg', name:'DAI (BEP20)',},
    {id:24,img:'https://app.0xprocessing.com/img/usdt.svg',name:'USDT (BEP20)'},
    {id:25,img:'https://app.0xprocessing.com/img/desu.svg',name:'DESU (BEP20)',},
    {id:26,img:'https://app.0xprocessing.com/img/ada.svg', name:'ADA (BEP20)',},
    {id:27,img:'https://app.0xprocessing.com/img/usdc.svg', name:'USDC (BEP20)',},
    {id:28,img:'https://app.0xprocessing.com/img/btc.svg',name:'BTCB (BEP20)'},
    {id:29,img:'https://app.0xprocessing.com/img/csc.svg',name:'CSC',},
]


export default Data

